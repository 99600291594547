import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// eslint-disable-next-line
import styles from "./App.css";

import Customer from "./component/Customer";
import Login from "./component/Login";
import { ProtectedRouteCustomer } from "./component/ProtectedRouteCustomer";
import { AuthProvider } from "./hooks/useAuth";
import { DarkModeProvider } from "./hooks/useDarkmode";
import { NavBar } from "./component/NavBar";
import { Body } from "./component/Body";
import Cashier from "./component/Cashier";
import { ProtectedRouteCashier } from "./component/ProtectedRouteCashier";
import CashierSignIn from "./component/CashierSignIn";

const App = () => {
    return (
        <BrowserRouter>
            <DarkModeProvider>
                <AuthProvider>
                    <Body>
                        <NavBar />
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route
                                path="/customer"
                                element={
                                    <ProtectedRouteCustomer>
                                        <Customer />
                                    </ProtectedRouteCustomer>
                                }
                            />
                            <Route
                                path="/cashier-signin"
                                element={
                                    <CashierSignIn />
                                }
                            />
                            <Route
                                path="/cashier"
                                element={
                                    <ProtectedRouteCashier>
                                        <Cashier />
                                    </ProtectedRouteCashier>
                                }
                            />
                        </Routes>
                    </Body>
                </AuthProvider>
            </DarkModeProvider>
        </BrowserRouter>
    );
};

export default App;

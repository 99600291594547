import React, { useEffect, useRef, useState } from "react";

import { getFirebase } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import QRCode from "react-qr-code";
import StatusModal from "./StatusModal";
import { Button } from "./Button";
import { useAuth } from "../hooks/useAuth";
import {
    addCountryCode,
    isTargetAddrVerified,
    isValidEmail,
    isValidPhoneNum,
    removeCountryCode,
} from "../utils/validate";
import {
    ERR,
    VERIF_FAILED,
    VERIF_SUCCESS,
    VERIFCODE_SENT,
} from "../utils/constants";
import { Input } from "./Input";

const { auth, firestore, functions } = getFirebase();

const Customer = () => {
    const [curPts, setCurPts] = useState(0);
    const [timeoutMs] = useState(30000); // 30s

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalRunning, setIsModalRunning] = useState(false);
    const [modalText, setModalText] = useState("");

    const [email, setEmail] = useState("");
    const [optInEmail, setOptInEmail] = useState(true);
    const [emailVerified, setEmailVerified] = useState(true);
    const [emailCode, setEmailCode] = useState("");
    const [didSendEmailCode, setDidSendEmailCode] = useState(false);
    const [emailResendRemSec, setEmailResendRemSec] = useState(
        timeoutMs / 1000
    );
    let eIntervalId = useRef(-1);

    const [phoneNum, setPhoneNum] = useState("");
    const [optInSMS, setOptInSMS] = useState(true);
    const [phoneVerified, setPhoneVerified] = useState(true);
    const [SMScode, setSMScode] = useState("");
    const [didSendSMSCode, setDidSendSMSCode] = useState(false);
    const [SMSResendRemSec, setSMSResendRemSec] = useState(timeoutMs / 1000);
    let sIntervalId = useRef(-1);

    const [showSMSCodeInput, setShowSMSCodeInput] = useState(false);
    const [showEmailCodeInput, setShowEmailCodeInput] = useState(false);

    const savePref = httpsCallable(functions, "savePreference");
    const sendVerifCode = httpsCallable(functions, "sendVerificationCode");
    const verifyCode = httpsCallable(functions, "verifyVerificationCode");
    const { user, logout } = useAuth();

    useEffect(() => {
        const userDoc = doc(firestore, "users", user.uid);
        onSnapshot(userDoc, (snapshot) => {
            let data = snapshot.data();
            if (data == null) {
                return;
            }
            setCurPts(data.points != null ? data.points : 0);

            setOptInEmail(data.sEmail != null ? data.sEmail : false);
            setEmail(data.email != null ? data.email : "");
            setEmailVerified(
                data.emailVerified != null ? data.emailVerified : false
            );

            setOptInSMS(data.sSMS != null ? data.sSMS : false);
            setPhoneNum(
                data.phoneNumber != null
                    ? removeCountryCode(data.phoneNumber)
                    : ""
            );
            setPhoneVerified(
                data.phoneVerified != null ? data.phoneVerified : false
            );
        });
    }, [user.uid]);

    useEffect(() => {
        if (emailResendRemSec === 0) {
            clearInterval(eIntervalId.current);
            setEmailResendRemSec(timeoutMs / 1000);
        }
        if (SMSResendRemSec === 0) {
            clearInterval(sIntervalId.current);
            setSMSResendRemSec(timeoutMs / 1000);
        }
    }, [SMSResendRemSec, emailResendRemSec, timeoutMs]);

    /*
     * Email verification helper functions
     * */
    const sendVerifEmailCode = async () => {
        setIsModalOpen(true);
        if (isValidEmail(email)) {
            setIsModalRunning(true);
            setShowEmailCodeInput(true);
            try {
                await sendVerifCode({
                    uid: user.uid,
                    channel: "email",
                    email: email,
                });
                setModalText(VERIFCODE_SENT);
                setDidSendEmailCode(true);
            } catch (e) {
                setModalText(ERR(e));
            } finally {
                setIsModalRunning(false);
                setEmailVerifInvalidTimeout();
            }
            return;
        }
        setModalText("Invalid email");
    };

    const verifyEmail = async () => {
        setIsModalOpen(true);
        setIsModalRunning(true);
        try {
            const resp = await verifyCode({
                uid: user.uid,
                channel: "email",
                email: email,
                code: emailCode,
            });
            if (isTargetAddrVerified(resp.data)) {
                setModalText(VERIF_SUCCESS);
                setOptInEmail(true);
                setEmailVerified(true);
                setShowEmailCodeInput(false);
                setDidSendEmailCode(false);
                setEmailCode("");
                return;
            }
            setModalText(VERIF_FAILED);
            setEmailVerified(false);
        } catch (e) {
            setModalText(ERR(e));
        } finally {
            setIsModalRunning(false);
        }
    };

    const setEmailVerifInvalidTimeout = () => {
        setTimeout(() => {
            setDidSendEmailCode(false);
        }, timeoutMs);

        eIntervalId.current = setInterval(() => {
            setEmailResendRemSec((prev) => prev - 1);
        }, 1000);
    };

    /*
     * SMS verification helper functions
     * */
    const sendVerifSMSCode = async () => {
        setIsModalOpen(true);
        if (isValidPhoneNum(phoneNum)) {
            setIsModalRunning(true);
            setShowSMSCodeInput(true);
            try {
                await sendVerifCode({
                    uid: user.uid,
                    channel: "sms",
                    phoneNumber: addCountryCode(phoneNum),
                });
                setModalText(VERIFCODE_SENT);
                setDidSendSMSCode(true);
            } catch (e) {
                setModalText(ERR(e));
            } finally {
                setIsModalRunning(false);
                setSMSVerifInvalidTimeout();
            }
            return;
        }
        setModalText("Invalid phone number");
    };

    const verifyPhoneNum = async () => {
        setIsModalOpen(true);
        setIsModalRunning(true);
        try {
            const resp = await verifyCode({
                uid: user.uid,
                channel: "sms",
                phoneNumber: addCountryCode(phoneNum),
                code: SMScode,
            });
            if (isTargetAddrVerified(resp.data)) {
                setModalText(VERIF_SUCCESS);
                setOptInSMS(true);
                setPhoneVerified(true);
                setShowSMSCodeInput(false);
                setDidSendSMSCode(false);
                setSMScode("");
                return;
            }
            setModalText(VERIF_FAILED);
            setPhoneVerified(false);
        } catch (e) {
            setModalText(ERR(e));
        } finally {
            setIsModalRunning(false);
        }
    };

    const setSMSVerifInvalidTimeout = () => {
        setTimeout(() => {
            setDidSendSMSCode(false);
        }, timeoutMs);

        sIntervalId.current = setInterval(() => {
            setSMSResendRemSec((prev) => prev - 1);
        }, 1000);
    };

    const savePrefs = async () => {
        if (optInEmail) {
            if (!isValidEmail(email)) {
                setIsModalOpen(true);
                setModalText("Please fill in a valid email");
                return;
            }
            if (!emailVerified) {
                setIsModalOpen(true);
                setModalText(
                    "Email isn't verified. Please try verifying it again."
                );
                return;
            }
        }
        if (optInSMS) {
            if (!isValidPhoneNum(phoneNum)) {
                setIsModalOpen(true);
                setModalText("Please fill in a valid phone number");
                return;
            }
            if (!phoneVerified) {
                setIsModalOpen(true);
                setModalText(
                    "Phone number isn't verified. Please try verifying it again."
                );
                return;
            }
        }
        const newEmail = optInEmail ? email : "";
        const newPhoneNum = optInSMS ? addCountryCode(phoneNum) : "";

        setIsModalOpen(true);
        setIsModalRunning(true);
        try {
            await savePref({
                uid: user.uid,
                sendEmail: optInEmail,
                email: newEmail,

                sendSMS: optInSMS,
                phoneNum: newPhoneNum,
            });
            setModalText("Successfully updated user preferences!");
        } catch (e) {
            setModalText(`Can't update preferences at the moment!: ${ERR(e)}`);
            console.error(e);
        } finally {
            setIsModalRunning(false);
        }
    };

    return (
        <div className="flex flex-col items-center text-center">
            <StatusModal
                isOpen={isModalOpen}
                isRunning={isModalRunning}
                text={modalText}
                handleClose={() => {
                    setIsModalOpen(false);
                }}
            />
            <div>
                <QRCode value={`${user.uid}`} />
                <h1
                    className={
                        "font-sans text-4xl font-bold text-primary-color-alternate"
                    }
                >
                    Your points: {curPts}
                </h1>
            </div>
            <div className={"my-10 mx-auto w-5/6"}>
                <form
                    className={
                        "flex flex-col items-center rounded-lg bg-neutral-color py-5 shadow-lg"
                    }
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <div className={"flex flex-row items-stretch"}>
                        <input
                            className={"mx-2 self-center"}
                            name={"optInEmail"}
                            id={"optInEmail"}
                            type={"checkbox"}
                            checked={optInEmail}
                            onChange={() => {
                                setOptInEmail(!optInEmail);
                            }}
                        />
                        <label className={"self-center"} htmlFor={"optInEmail"}>
                            Opt-in for email promo
                        </label>
                    </div>
                    <Input
                        isOptIn={optInEmail}
                        didSendCode={didSendEmailCode}
                        validateInput={isValidEmail}
                        helperText={
                            "Email should be in this format: abc@xyz.com"
                        }
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailVerified(false);
                        }}
                    />
                    {showEmailCodeInput && (
                        <Input
                            isOptIn={true}
                            didSendCode={false} // hacky way to reuse Input component
                            validateInput={() => true}
                            value={emailCode}
                            onChange={(e) => setEmailCode(e.target.value)}
                        />
                    )}
                    <Button
                        disabled={didSendEmailCode}
                        onClick={() => {
                            sendVerifEmailCode();
                        }}
                    >
                        Send Email Verification Code{" "}
                        {didSendEmailCode ? (
                            <div>({emailResendRemSec})</div>
                        ) : null}
                    </Button>
                    {showEmailCodeInput && (
                        <Button onClick={() => verifyEmail()}>
                            Verify Email
                        </Button>
                    )}
                    <div className={"my-8"}></div>
                    <div className={"flex flex-row items-stretch"}>
                        <input
                            className={"mx-2 self-center"}
                            name={"optInSMS"}
                            id={"optInSMS"}
                            type={"checkbox"}
                            checked={optInSMS}
                            onChange={() => {
                                setOptInSMS(!optInSMS);
                            }}
                        />
                        <label className={"self-center"} htmlFor={"optInSMS"}>
                            Opt-in for SMS promo
                        </label>
                    </div>
                    <Input
                        isOptIn={optInSMS}
                        didSendCode={didSendSMSCode}
                        validateInput={isValidPhoneNum}
                        helperText={
                            "Phone number should be in this format: 4164567890"
                        }
                        value={phoneNum}
                        onChange={(e) => {
                            setPhoneNum(e.target.value);
                            setPhoneVerified(false);
                        }}
                    />
                    {showSMSCodeInput && (
                        <Input
                            isOptIn={true}
                            didSendCode={false} // hacky way to reuse Input component
                            validateInput={() => true}
                            value={SMScode}
                            onChange={(e) => setSMScode(e.target.value)}
                        />
                    )}
                    <Button
                        disabled={didSendSMSCode}
                        onClick={() => {
                            sendVerifSMSCode();
                        }}
                    >
                        Send SMS Verification Code{" "}
                        {didSendSMSCode ? <div>({SMSResendRemSec})</div> : null}
                    </Button>
                    {showSMSCodeInput && (
                        <Button onClick={() => verifyPhoneNum()}>
                            Verify Phone Number
                        </Button>
                    )}
                </form>
            </div>
            <div className={"flex flex-col items-center"}>
                <Button onClick={() => savePrefs()}>Save Preferences</Button>
                <Button
                    customCSS={"mt-5"}
                    onClick={() => {
                        auth.signOut().then(logout);
                    }}
                >
                    Sign out
                </Button>
            </div>
        </div>
    );
};

export default Customer;

import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProtectedRouteCashier = ({ children }) => {
    const { user } = useAuth();

    if (user == null) {
        return <Navigate to={"/"} />;
    }

    if (user.role === "cashier") {
        return children;
    }

    return (
        <h1>
            403. You're not authenticated as a cashier. Please contact
            restaurant's owner to receive authorization code for new cashier
            account
        </h1>
    );
};

import React, { useState } from "react";

import { signInWithEmailAndPassword } from "firebase/auth";
import { getFirebase } from "../firebase";
import { httpsCallable } from "firebase/functions";

import { Button } from "./Button";
import { useAuth } from "../hooks/useAuth";
import {
    isValidAuthCode,
    isValidEmail,
    isValidPassword,
} from "../utils/validate";

const { auth, functions } = getFirebase();

const CashierSignIn = () => {
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const createCashier = httpsCallable(functions, "createCashier");
    const isCashier = httpsCallable(functions, "isCashier");
    const { login } = useAuth();

    const signIn = async () => {
        try {
            await isCashier({ email: email });
            let userCred = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            let cUser = {
                role: "cashier",
                uid: userCred.user.uid,
            };
            login(cUser, "/cashier");
        } catch (e) {
            alert(
                "Couldn't sign in cashier. Please check your email and password."
            );
        }
    };

    const signUp = async () => {
        if (!isValidEmail(email)) {
            alert("Please type in a valid email");
            return;
        }
        if (!isValidPassword(password)) {
            alert(
                "A valid password must contain:\n- at least 6 characters\n- at most 20 characters\n- at least 1 lowercase\n- at least 1 uppercase\n- at least 1 special character (i.e !@#$%^&*()+=)"
            );
            return;
        }
        if (!isValidAuthCode(code)) {
            alert("Please fill in a valid authorization code");
            return;
        }
        try {
            await createCashier({
                email: email,
                password: password,
                code: code,
            });
            let userCred = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            let cUser = {
                role: "cashier",
                uid: userCred.user.uid,
            };
            login(cUser, "/cashier");
        } catch (e) {
            alert(`Couldn't sign up cashier: ${e}`);
        }
    };

    return (
        <div className="mx-auto w-5/6">
            <form
                className={
                    "flex flex-col items-center rounded-lg bg-neutral-color shadow-lg"
                }
                onSubmit={(e) => e.preventDefault()}
            >
                <input
                    className={"mt-8 mb-2.5 block"}
                    type={"text"}
                    id={"email"}
                    name="email"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    className={"my-2.5 block"}
                    type={"password"}
                    id={"password"}
                    name="password"
                    value={password}
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input
                    className={"my-2.5 block"}
                    type={"text"}
                    id={"code"}
                    name="code"
                    value={code}
                    placeholder="Authorization code"
                    onChange={(e) => setCode(e.target.value)}
                />
                <Button
                    customCSS={"my-2.5"}
                    onClick={() => signIn()}
                >
                    Sign In
                </Button>
                <Button
                    customCSS={"mt-2.5 mb-8"}
                    onClick={() => signUp()}
                >
                    Sign Up
                </Button>
            </form>
        </div>
    );
};

export default CashierSignIn;

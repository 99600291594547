import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const ProtectedRouteCustomer = ({ children }) => {
    const { user } = useAuth();

    if (user == null) {
        return <Navigate to={"/"} />;
    }

    if (user.role === "customer") {
        return children;
    }

    return (
        <h1>
            403. You're not authenticated as a customer. Please create a new
            customer account.
        </h1>
    );
};

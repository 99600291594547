import { createContext, useContext, useState } from "react";

const DarkModeContext = createContext(null);

export const DarkModeProvider = ({ children }) => {
    const [dark, setDark] = useState(false);

    return (
        <DarkModeContext.Provider value={[dark, setDark]}>{children}</DarkModeContext.Provider>
    );
};

export const useDarkmode = () => {
    return useContext(DarkModeContext);
};

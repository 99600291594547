import React from "react";

export const Input = ({
    isOptIn,
    didSendCode,
    value,
    onChange,
    validateInput,
    helperText,
    customCSS,
}) => {
    const inputStyle =
        isOptIn && !validateInput(value)
            ? `${customCSS} border-2 border-red-600 active:border-red-600 focus:border-red-600`
            : `${customCSS}`;

    return (
        <div className={"border-red-50 mb-5 flex w-full flex-col items-center"}>
            <input
                className={`mt-2.5 w-5/6 md:w-3/4 lg:w-2/3 ${inputStyle}`}
                type={"text"}
                disabled={!isOptIn || didSendCode}
                value={value}
                onChange={onChange}
            />
            {isOptIn && !validateInput(value) ? (
                <span className={"text-red-600"}>{helperText}</span>
            ) : null}
        </div>
    );
};

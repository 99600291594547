import React, { useEffect } from "react";

import {
    EmailAuthProvider,
    GoogleAuthProvider,
    PhoneAuthProvider,
} from "firebase/auth";
import { getFirebase } from "../firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

import { useAuth } from "../hooks/useAuth";
import { geohashForLocation } from "geofire-common";
import { doc, setDoc } from "firebase/firestore";

const { firebaseApp, auth, firestore } = getFirebase();

const CustomerSignIn = () => {
    const { login, logout } = useAuth();
    let ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebaseApp.auth());
    const uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            GoogleAuthProvider.PROVIDER_ID,
            EmailAuthProvider.PROVIDER_ID,
            {
                provider: PhoneAuthProvider.PROVIDER_ID,
                recaptchaParameters: {
                    type: "image",
                    size: "normal",
                    badge: "bottomleft",
                },
                defaultCountry: "CA",
                defaultNationalNumber: "1234567890",
                loginHint: "+11234567890",
            },
        ],
        callbacks: {
            signInSuccessWithAuthResult: () => false,
        },
    };

    useEffect(() => {
        ui.start("#firebase-ui", uiConfig);
        return auth.onAuthStateChanged(async (user) => {
            if (user == null) {
                logout();
                return;
            }
            // prevent user signing in from cashier input field
            if (
                !!user &&
                (await user.getIdTokenResult()).claims.role !== "cashier"
            ) {
                const userDoc = doc(firestore, "users", user.uid);
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        let lat = position.coords.latitude;
                        let long = position.coords.longitude;
                        let coords = {
                            geohash: geohashForLocation([lat, long]),
                            lat: lat,
                            long: long,
                        };
                        setDoc(userDoc, coords, { merge: true });
                    },
                    (e) => {
                        console.error(e.message);
                    }
                );
                let cUser = {
                    role: "customer",
                    uid: user.uid,
                };
                login(cUser, `/customer`);
            }
        });
        // eslint-disable-next-line
    }, []);

    return <div id="firebase-ui"></div>;
};

export default CustomerSignIn;

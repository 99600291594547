import React from "react";
import CustomerSignIn from "./CustomerSignIn";

const Login = () => {
    return (
        <div className={"text-center"}>
            <CustomerSignIn />
        </div>
    );
};

export default Login;

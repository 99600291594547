import firebase from "firebase/compat/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { config } from "./config";

export function getFirebase() {
    const firebaseApp = firebase.initializeApp(config.firebase);
    const auth = getAuth(firebaseApp);
    const firestore = getFirestore(firebaseApp);
    const functions = getFunctions(firebaseApp);
    // TODO: replace with your machine's IP address
    if (
        // eslint-disable-next-line no-restricted-globals
        location.hostname === "localhost" ||
        // eslint-disable-next-line no-restricted-globals
        location.hostname === "192.168.2.109"
    ) {
        // eslint-disable-next-line no-restricted-globals
        connectAuthEmulator(auth, `http://${location.hostname}:9099`, {
            disableWarnings: true,
        });
        // eslint-disable-next-line no-restricted-globals
        connectFirestoreEmulator(firestore, location.hostname, 8080);
        // eslint-disable-next-line no-restricted-globals
        connectFunctionsEmulator(functions, location.hostname, 5001);
    }
    return { firebaseApp, auth, firestore, functions };
}

export const config = {
    firebase: {
        apiKey: "AIzaSyB1hFWM3ePTnmVNFmOO0knSl1xqpYBsY4c",
        authDomain: "gingerrewards-290e0.firebaseapp.com",
        projectId: "gingerrewards-290e0",
        storageBucket: "gingerrewards-290e0.appspot.com",
        messagingSenderId: "547992514873",
        appId: "1:547992514873:web:f37003524683ca1f02b8e8",
        measurementId: "G-KQ5FN0BRQ9",
    },
    devMapsAPIKey: "",
    mapsAPIKey: "AIzaSyDIHtXPahsVNgpaICH9buKjZnOKyg781YY",
};

export const isValidEmail = (email) => {
    return email?.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$");
};

export const isValidPhoneNum = (phone) => {
    return phone?.match("^[0-9]{10}$");
};

export const isValidPassword = (password) => {
    return password?.match(
        "^(?=.{6,20})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=]).*$"
    );
};

export const isValidAuthCode = (code) => {
    return code?.length > 0;
};

export const isTargetAddrVerified = (resp) => {
    return resp?.status.match("^approved$");
};

export const addCountryCode = (phoneNum) => {
    return `+1${phoneNum}`;
};

export const removeCountryCode = (phoneNum) => {
    return phoneNum.substring(2);
};

import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import React from "react";

const StatusModal = ({ isOpen, isRunning, text, handleClose }) => {
    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Box
                className={
                    "absolute top-1/2 left-1/2 w-1/3 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-neutral-color p-6 shadow-lg"
                }
            >
                <Box>
                    <Typography id="title" variant="h6" component="h2">
                        Status
                    </Typography>
                </Box>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    {isRunning ? (
                        <CircularProgress
                            sx={{ mt: 2, color: "rgb(6 78 59)" }}
                        /> // primary-color
                    ) : (
                        <Typography id="content" sx={{ mt: 2 }}>
                            {text}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default StatusModal;

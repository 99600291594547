import React, { useEffect, useState } from "react";

import { getFirebase } from "../firebase";
import { doc, increment, onSnapshot, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import scanOverlay from "./ScanOverlay";
import { useAuth } from "../hooks/useAuth";
import { QrReader } from "react-qr-reader";

import moneyPtsMapping from "../settings/moneyMapping.json";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { Button } from "./Button";
import { Input } from "./Input";
import StatusModal from "./StatusModal";
import { useDarkmode } from "../hooks/useDarkmode";

const { auth, firestore, functions } = getFirebase();

const Cashier = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalRunning, setIsModalRunning] = useState(false);
    const [modalText, setModalText] = useState("");

    const [uid, setUid] = useState("");
    const [ptsDiff, setPtsDiff] = useState(0);
    const [curPts, setCurPts] = useState(-1);
    const [open, setOpen] = useState(false);
    const [msgContent, setMsgContent] = useState("");

    const [lat, setLat] = useState(0.0);
    const [long, setLong] = useState(0.0);
    const [radiusInM, setRadiusInM] = useState(1000);
    const [zoom, setZoom] = useState(14);

    const { logout } = useAuth();
    // eslint-disable-next-line no-unused-vars
    const [dark, _] = useDarkmode();
    const sendPromoMessage = httpsCallable(functions, "sendPromoMessage");

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            let lat = position.coords.latitude;
            let long = position.coords.longitude;
            console.log(`lat: ${lat}, lng: ${long}`);
            setLat(lat);
            setLong(long);
        });
    });

    const checkPointBalance = (e) => {
        e.preventDefault();
        const userDoc = doc(firestore, "users", uid);
        onSnapshot(userDoc, (snapshot) => {
            let data = snapshot.data();
            if (data == null) {
                setIsModalOpen(true);
                setModalText("Invalid uid input. Please try again");
                return;
            }
            setCurPts(data.points);
        });
    };

    const add = () => {
        const userDoc = doc(firestore, "users", uid);
        setDoc(userDoc, { points: increment(ptsDiff) }, { merge: true });
    };

    const redeem = () => {
        if (curPts < ptsDiff) {
            setIsModalOpen(true);
            setModalText("Cannot redeem: Not enough points");
            return;
        }
        const userDoc = doc(firestore, "users", uid);
        setDoc(userDoc, { points: increment(-ptsDiff) }, { merge: true });
        setOpen(false);
    };

    const clear = () => {
        setPtsDiff(0);
        setCurPts(-1);
        setUid("");
    };

    const sendPromoMsg = async () => {
        setIsModalOpen(true);
        setIsModalRunning(true);
        try {
            const numUsers = await sendPromoMessage({
                msg: msgContent,
                lat: lat,
                long: long,
                radiusInM: radiusInM,
            });
            setIsModalRunning(false);
            setModalText(
                `Successfully send message to ${numUsers.data} customers`
            );
        } catch (e) {
            setIsModalRunning(false);
            setModalText(`Can't send promotional message to customers: ${e}`);
        }
    };

    return (
        <div className="flex flex-col items-center text-center">
            <StatusModal
                isOpen={isModalOpen}
                isRunning={isModalRunning}
                text={modalText}
                handleClose={() => {
                    setIsModalOpen(false);
                }}
            />
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Redeem</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Redeem {ptsDiff} points for user {uid}?
                    </DialogContentText>
                    <Input
                        isOptIn={true}
                        didSendCode={false} // hacky way to reuse Input component
                        validateInput={() => true}
                        value={ptsDiff}
                        onChange={(e) => setPtsDiff(Number(e.target.value))}
                    />
                </DialogContent>
                <DialogActions>
                    <Button customCSS={"mb-4"} onClick={redeem}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <div className={"mx-auto mb-5 w-5/6 lg:w-3/4 xl:w-1/2"}>
                <form
                    className={
                        "flex flex-col items-center rounded-lg bg-neutral-color py-5 shadow-lg"
                    }
                    onSubmit={(e) => e.preventDefault()}
                >
                    <Input
                        customCSS={"md:w-2/3 lg:w-1/2 xl:w-3/5 2xl:w-1/4"}
                        isOptIn={true}
                        didSendCode={false} // hacky way to reuse Input component
                        validateInput={() => true}
                        value={uid}
                        onChange={(e) => {
                            e.target.value === ""
                                ? clear()
                                : setUid(e.target.value);
                        }}
                    />
                    <Button
                        customCSS={
                            dark
                                ? "bg-primary-color border-secondary-color text-secondary-color"
                                : ""
                        }
                        onClick={checkPointBalance}
                    >
                        Check points balance
                    </Button>
                </form>
            </div>
            {curPts > -1 ? (
                <h1
                    className={
                        "font-sans text-4xl font-bold text-primary-color-alternate"
                    }
                >
                    Points: {curPts}
                </h1>
            ) : (
                <br></br>
            )}
            {ptsDiff > 0 ? (
                <h2
                    className={
                        "font-sans text-2xl text-primary-color-alternate"
                    }
                >
                    Amount: {ptsDiff}
                </h2>
            ) : (
                <br></br>
            )}
            <div
                className={
                    "grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3"
                }
            >
                {Object.keys(moneyPtsMapping).map((money) => (
                    <Button
                        key={moneyPtsMapping[money]}
                        customCSS={`my-5 py-5 ${
                            dark
                                ? "bg-primary-color border-secondary-color text-secondary-color"
                                : ""
                        }`}
                        onClick={() => setPtsDiff(moneyPtsMapping[money])}
                    >
                        {money}
                    </Button>
                ))}
            </div>
            <div className={"my-4"}></div>
            <div className={"mb-5 flex flex-row items-stretch"}>
                <Button
                    customCSS={
                        dark
                            ? "bg-primary-color border-secondary-color text-secondary-color"
                            : ""
                    }
                    onClick={add}
                >
                    Add
                </Button>
                <Button
                    customCSS={
                        dark
                            ? "bg-primary-color border-secondary-color text-secondary-color"
                            : ""
                    }
                    onClick={() => setOpen(true)}
                >
                    Redeem
                </Button>
            </div>
            <Button
                customCSS={
                    dark
                        ? "bg-primary-color border-secondary-color text-secondary-color"
                        : ""
                }
                onClick={clear}
            >
                Clear
            </Button>
            <QrReader
                delay={300}
                onResult={(result) => {
                    if (!!result) {
                        setUid(result.getText());
                    }
                }}
                containerStyle={{
                    width: "30%",
                }}
                videoStyle={{ marginTop: "0", width: "100%" }}
                ViewFinder={scanOverlay}
                constraints={{
                    facingMode: "environment",
                }}
            />
            {/*<div className={"my-8"}>*/}
            {/*    <label className={dark ? "text-neutral-color" : ""}>*/}
            {/*        Radius in Meters*/}
            {/*    </label>*/}
            {/*    <Input*/}
            {/*        customCSS={"md:w-2/3 lg:w-1/2 xl:w-3/5 2xl:w-1/4"}*/}
            {/*        isOptIn={true}*/}
            {/*        didSendCode={false} // hacky way to reuse Input component*/}
            {/*        validateInput={() => true}*/}
            {/*        value={radiusInM}*/}
            {/*        onChange={(e) => setRadiusInM(Number(e.target.value))}*/}
            {/*    />*/}
            {/*    <Map*/}
            {/*        center={{ lat: lat, lng: long }}*/}
            {/*        radiusInM={radiusInM}*/}
            {/*        zoom={zoom}*/}
            {/*        setZoom={setZoom}*/}
            {/*    />*/}
            {/*    <TextField*/}
            {/*        label={"Promotional Content"}*/}
            {/*        value={msgContent}*/}
            {/*        onChange={(e) => setMsgContent(e.target.value)}*/}
            {/*        multiline*/}
            {/*        style={{ width: "50%" }}*/}
            {/*        rows={10}*/}
            {/*    />*/}
            {/*    <Button*/}
            {/*        onClick={() => {*/}
            {/*            sendPromoMsg();*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Send to customers*/}
            {/*    </Button>*/}
            {/*</div>*/}
            <Button
                onClick={() => {
                    auth.signOut()
                        .then(logout)
                        .finally(() => {
                            // eslint-disable-next-line no-restricted-globals
                            location.reload(); // to turn off camera
                        });
                }}
            >
                Sign out
            </Button>
        </div>
    );
};

export default Cashier;

import { ButtonUnstyled } from "@mui/base";
import React from "react";

export const Button = ({ children, ...props }) => {
    const buttonStyle = `${props.customCSS} mx-24 rounded-lg border-2 border-primary-color bg-secondary-color px-10 py-2 font-bold text-primary-color 
    hover:border-primary-color-alternate hover:bg-secondary-color-alternate hover:text-primary-color-alternate`;

    return (
        <ButtonUnstyled
            className={buttonStyle}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {children}
        </ButtonUnstyled>
    );
};

const ScanOverlay = () => {
    const styles = {
        scanOverlay: {
            top: "25%",
            left: "25%",
            zIndex: 1,
            border: "50 solid transparent",
            position: "absolute",
            width: "50%",
            height: "50%",
        },
    };
    return (
        <svg viewBox={"0 0 100 100"} style={styles.scanOverlay}>
            <path
                fill={"none"}
                d={"M13,0 L0,0 L0,13"}
                stroke="rgba(255, 0, 0, 0.9)"
                strokeWidth={"5"}
            ></path>
            <path
                fill={"none"}
                d={"M0,87 L0,100 L13,100"}
                stroke="rgba(255, 0, 0, 0.9)"
                strokeWidth={"5"}
            ></path>
            <path
                fill={"none"}
                d={"M87,100 L100,100 L100,87"}
                stroke="rgba(255, 0, 0, 0.9)"
                strokeWidth={"5"}
            ></path>
            <path
                fill={"none"}
                d={"M100,13 L100,0 87,0"}
                stroke="rgba(255, 0, 0, 0.9)"
                strokeWidth={"5"}
            ></path>
        </svg>
    );
};

export default ScanOverlay;

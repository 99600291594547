import { Button } from "./Button";
import { useDarkmode } from "../hooks/useDarkmode";

export const NavBar = () => {
    const [dark, setDark] = useDarkmode();

    return (
        <div>
            {/*<Button onClick={() => {setDark(!dark)}}>Set Dark mode</Button>*/}
            <header
                className={
                    "mb-10 w-full p-7 text-center text-6xl font-bold text-primary-color-alternate"
                }
            >
                Ginger Rewards
            </header>
        </div>
    );
}
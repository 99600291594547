import { useDarkmode } from "../hooks/useDarkmode";

export const Body = ({ children }) => {
    // eslint-disable-next-line no-unused-vars
    const [dark, _] = useDarkmode();

    return (
        <div
            className={`m-0 min-h-screen bg-gradient-to-b from-primary-color ${
                dark ? "to-black" : "to-neutral-color"
            } p-0 font-mono `}
        >
            {children}
        </div>
    );
};
